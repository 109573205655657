export const seo = {
  url: 'company/supporting',
  title: {
    pl: 'Wspieramy',
    en: 'Supporting',
  },
  desc: {
    pl: `Omida Sea And Air od lat wspiera sport i pasję. Chcemy być częścią wydarzeń, mistrzostw oraz wsparciem dla młodych talentów!`,
    en: `Omida Sea And Air has been supporting sport and passion for years. We want to be part of the events, championships and support for young talents!`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'wspieramy',
    'aleksandra wasiewicz',
    'sport',
    'omida bulldgos',
  ],
}

export const intro = {
  title: {
    pl: 'Wspieramy',
    en: 'Supporting',
  },
  desc: {
    pl: `Omida Sea And Air od lat wspiera sport i pasję. Poznaj naszych sportowych reprezentantów!`,
    en: `Omida Sea And Air has been supporting sport and passion for years. Meet our sports representatives!`,
  },
}

export const main = {
  title: {
    pl: 'Omida Sea And Air <br/>wspiera sport!',
    en: 'Omida Sea And Air <br/>supports sport!',
  },
  desc: {
    pl: 'Chcemy być częścią wydarzeń, mistrzostw oraz wsparciem dla młodych talentów, które z pasją i zamiłowaniem kochają te same żywioły co my. Kibicuj z nami i poznaj najbliższe terminy zawodów!',
    en: 'We want to be part of the events, championships and support for young talents who love the same elements with passion and love as we do. Cheer with us and learn about the next dates of the competition!',
  },
}
