import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import { seo, intro, main } from '../../../content/wspieramy'
import Intro from '../../../components/Intro'
import Button from '../../../components/Button'
import LinkTile from '../../../components/LinkTile'
import Main from '../../../components/Main'
import { sLinkTiles } from '../../../style'

const Wspieramy = () => {
  const { lang } = useLangContext()

  const { image, windsurfing, bulldogs } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/wspieramy.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      windsurfing: file(
        absolutePath: { regex: "/images/intros/aleksandra-wasiewicz.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
      bulldogs: file(
        absolutePath: {
          regex: "/images/supporting/bulldogs/omida-bulldogs.jpg/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },
    action: 'SCROLL',
  }

  const links = [
    {
      slug: `/company/supporting/aleksandra-wasiewicz/`,
      title: 'Aleksandra Wasiewicz',
      image: windsurfing.childImageSharp,
      subtitle: {
        pl: 'Windsurfing',
        en: 'Windsurfing',
      },
    },
    {
      slug: `/company/supporting/omida-bulldogs/`,
      title: 'Omida Bulldogs',
      image: bulldogs.childImageSharp,
      subtitle: {
        pl: 'Koszykówka',
        en: 'Basketball',
      },
    },
  ]

  const buttonBack = {
    text: {
      pl: 'Powrót do firmy',
      en: 'Back to company',
    },
    link: '/company/',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro data={{ ...intro, button }} image={image.childImageSharp} />
      <Main h={1} title={main.title[lang]} body={main.desc[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </Main>
    </Layout>
  )
}

export default Wspieramy
